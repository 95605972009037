import {
  StyledCard,
  StyledCelestialObject,
  StyledDescription,
  StyledButton
} from "./StyledCelestialObjectCard";

const CelestialObjectCard = ({
  className,
  color,
  name,
  description,
  to
}) => (
  <StyledCard className={className}>
    <StyledCelestialObject>
      <img src={color} alt="img" />
    </StyledCelestialObject>
    <StyledDescription>{description}</StyledDescription>
    <StyledButton to={to || '/'}>{name}</StyledButton>
  </StyledCard>
);

export default CelestialObjectCard;
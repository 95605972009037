import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";

import { planets } from "../../constants"

import PageHeader from "../../components/PageHeader/PageHeader";
import CelestialObjectCardsContainer from "../../components/CelestialObjectCardsContainer/CelestialObjectCardsContainer";
import CelestialObjectCard from "../../components/CelestialObjectCard/CelestialObjectCard"

import {
  StyledPageHeaderLoadingBox,
  StyledCelestialObjectLoadingBox,
  StyledCelestialObjectSection,
  StyledInformationLoadingBox,
  StyledCelestialObject,
  StyledCelestialObjectInformation,
  StyledMoonsSection
} from "./StyledBody";

const BodyPage = () => {
  const [ isLoading, setIsLoading ] = useState(false);
  const [ data, setData ] = useState(null);
  const params = useParams();
  

  useEffect(() => {
    const fetchData = () => {
      setIsLoading(true);
      const object = planets.find(obj => obj.id === params.id);

      if (object) {
        setData(object);
        setIsLoading(false);
      } else {
        setData(null);
        setIsLoading(false);
      }
    };

    fetchData();

    // Cleanup
    return () => {
      setIsLoading(true);
      setData(null);
    };
  }, [params.id]);

  // Function to generate a random integer between min and max (inclusive)
  const getRandomInt = (min, max) => {
    return Math.floor(Math.random() * (max - min + 1)) + min;
  };

  // Generate 4 unique random numbers
  const getRandomNumbers = () => {
    const numbers = [];
    while (numbers.length < 4) {
      const randomNumber = getRandomInt(1, 8);
      if (!numbers.includes(randomNumber)) {
        numbers.push(randomNumber);
      }
    }
    return numbers;
  };

  const randomNumbers = getRandomNumbers();

  const selectedObjects = [];

  randomNumbers.forEach(number => {
    const foundObject = planets.find(obj => obj.id === "SLT-00" + String(number));
    if (foundObject) {
      selectedObjects.push(foundObject);
    }
  });

  if (isLoading) return (
    <>
      <PageHeader>
        <StyledPageHeaderLoadingBox />
      </PageHeader>

      <StyledCelestialObjectSection>
        <StyledCelestialObjectLoadingBox />
        <StyledInformationLoadingBox />
      </StyledCelestialObjectSection>
    </>
  )

  if (!data) return (
    <>
      <PageHeader title="Error" />
      <article>
        <p>Unable to find celestial body with the ID "{params.id}".</p>
      </article>
    </>
  )

  return (
    <>
      {/* Render your data here */}
      {isLoading ? (
        <p>Loading...</p>
      ) : data ? (
        <div>
          <PageHeader title={data.name} />
          <br/>
          <StyledCelestialObjectSection>
            <StyledCelestialObject>
              <img src={data.color} alt="img" />
            </StyledCelestialObject>
            <StyledCelestialObjectInformation data={data} />
          </StyledCelestialObjectSection>
          <StyledMoonsSection>
            <h2>Other Products</h2>
            <CelestialObjectCardsContainer>

            {selectedObjects.map((planet) => (
              <CelestialObjectCard
                key={planet.id}
                color={planet.color}
                name={planet.name}
                description={planet.description}
                to={`/services/${planet.id}`}
              />
            ))}
      
            </CelestialObjectCardsContainer>
          </StyledMoonsSection>
        </div>
      ) : (
        <p>Data not found</p>
      )}
    </>
  );
}

export default BodyPage;
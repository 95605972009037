import PageHeader from "../../components/PageHeader/PageHeader";
import { AboutH2, AboutH1, AboutP, AboutSection } from "./StyledAbout";
import { Link } from "react-router-dom";

const AboutPage = () => {
  return (
    <AboutSection>
      <PageHeader title="About Us" />
      <article>
        <AboutP>
          At SlashTech, we're passionate about revolutionizing education through 
          technology. Founded with a vision to empower students and educators with 
          innovative tools and resources, SlashTech is committed to bridging the 
          gap between traditional education and the digital age.
        </AboutP>
        <AboutH1>Our Mission</AboutH1>
        <AboutP>
          Our mission at SlashTech is to inspire and empower the next generation of 
          creators, innovators, and problem solvers. We strive to provide accessible 
          and engaging educational experiences that foster critical thinking, 
          creativity, and lifelong learning.
        </AboutP>
        <AboutH1>What We Do</AboutH1>
        <AboutP>
          SlashTech specializes in research and development within educational 
          institutions, offering a wide range of services tailored to meet the unique 
          needs of schools and educators. From immersive learning workshops to cutting-edge 
          technology integration, we aim to equip students with the skills and knowledge necessary 
          to thrive in the digital world.
        </AboutP>
        <AboutH1>Our Services</AboutH1>
        <AboutH2>Learning Workshops:</AboutH2>
        <AboutP>
          We design and deliver hands-on workshops covering 
          various aspects of technology, including programming, robotics, electronics, 
          and more. Our workshops are designed to engage students in interactive learning 
          experiences that ignite curiosity and passion for technology.
        </AboutP>
        <AboutH2>Technology Integration:</AboutH2>
        <AboutP>
          We work closely with schools to integrate technology 
          seamlessly into their curriculum and learning environments. Whether it's setting 
          up maker spaces, coding clubs, or implementing STEM programs, we provide guidance 
          and support every step of the way.
        </AboutP>
        <AboutH2>Grant Assistance:</AboutH2>
        <AboutP>
          SlashTech offers assistance with grant applications and funding 
          opportunities to help schools secure resources for technology initiatives. From 
          researching available grants to writing compelling proposals, we're dedicated to 
          helping schools access the resources they need to enhance their educational offerings.
        </AboutP>
        <AboutH2>Commercial and Domestic Variations:</AboutH2>
        <AboutP>
          In addition to educational projects, SlashTech provides commercial and domestic 
          variations of our projects. Whether it's for commercial applications, home automation, 
          or personal projects, we offer customizable solutions to meet diverse needs.
        </AboutP>
        <AboutH1>Our Values</AboutH1>
        <AboutH2>Innovation:</AboutH2>
        <AboutP>
          We embrace innovation as a driving force behind progress and strive to push the boundaries 
          of what's possible in education.
        </AboutP>
        <AboutH2>Collaboration:</AboutH2>
        <AboutP>
          We believe in the power of collaboration and work closely with schools, educators, and 
          industry partners to achieve shared goals and objectives.
        </AboutP>
        <AboutH2>Accessibility:</AboutH2>
        <AboutP>
          We are committed to making technology education accessible to all students, regardless 
          of background or circumstance.
        </AboutP>
        <AboutH1>Get in Touch</AboutH1>
        <AboutP>
          Ready to take your school's technology initiatives to the next level? Contact us today 
          to learn more about our services and how we can support your educational goals.
        </AboutP>
        <Link to="/contact" >
          CONTACT NOW
        </Link>
      </article>
    </AboutSection>
  );
};

export default AboutPage;
import Button from "../Button/Button";
import promo from "../../imges/Workshop-modified.png"

import {
  StyledContentContainer,
  StyledDeimos,
  StyledHeading,
  StyledMarsAndMoonsContainer,
  StyledParagraph,
  StyledPhobos,
  StyledSection
} from "./StyledMarsPromo";

const MarsPromo = () => (
  <StyledSection>
    <StyledContentContainer>
      <StyledHeading>Ignite Your Curiosity with Hands-On Workshops!</StyledHeading>
      <StyledParagraph>Explore coding, robotics, and more in our engaging sessions. Unleash your inner innovator with us.</StyledParagraph>
      <Button $isRed to="/services/SLT-001">Explore Workshops</Button>
    </StyledContentContainer>
    <StyledMarsAndMoonsContainer>
      <img src={promo} alt="Logo" />
      <StyledPhobos />
      <StyledDeimos />
    </StyledMarsAndMoonsContainer>
  </StyledSection>
);

export default MarsPromo;
import img1 from "../../imges/Partnerships-modified.png"
import img2 from "../../imges/SmartAgriculture-modified.png"
import img3 from "../../imges/SustainableTech-modified.png"

import {
  StyledImag,
  StyledSection,
  StyledHeading, 
  StyledPlanet,
  StyledPlanetParagraph,
  StyledHeadingCollom
} from "./StyledPromo";
 
const PromoSection = () => (
  <StyledSection>
    <StyledHeading>Knowledge Right At Your Fingertips</StyledHeading>
    <StyledPlanet>
      <StyledImag><img src={img1} alt="img1" height='75%' width='75%'/></StyledImag>
      <StyledPlanetParagraph>
        <StyledHeadingCollom>Educational Partnerships</StyledHeadingCollom>
        Forge educational partnerships with S/ash Tech. Collaborate with us to bring cutting-edge technology education to your institution, tailored to your unique requirements.
      </StyledPlanetParagraph>
    </StyledPlanet>
    <StyledPlanet>
      <StyledImag><img src={img2} alt="img1" height='75%' width='75%'/></StyledImag>
      <StyledPlanetParagraph>
      <StyledHeadingCollom>Smart Agriculture Projects</StyledHeadingCollom>
        Explore the synergy of technology and agriculture through our smart projects. Witness the impact of IoT in farming, from automated irrigation to precision farming techniques.
      </StyledPlanetParagraph>
    </StyledPlanet>
    <StyledPlanet>
      <StyledImag><img src={img3} alt="img1" height='75%' width='75%'/></StyledImag>
      <StyledPlanetParagraph>
      <StyledHeadingCollom>Sustainable Tech Solutions</StyledHeadingCollom>
        Discover how technology can contribute to sustainability. Our solutions empower students to develop eco-friendly tech applications that address real-world challenges.
      </StyledPlanetParagraph>
    </StyledPlanet>
  </StyledSection>
)

export default PromoSection;
import {
  StyledSection,
  StyledContentContainer,
  StyledHeading,
  StyledParagraph,
  StyledButton,
  StyledEarthAndMoonContainer,
  StyledWaveBackground
} from "./StyledHero";
import logo from "../../imges/SlashTechLogo.png"


const HeroSection = () => (
  <StyledSection>
    <StyledEarthAndMoonContainer>
      <img src={logo} alt="Logo" />
    </StyledEarthAndMoonContainer>
    <StyledContentContainer>
      <StyledHeading> 
        Growing Ideas,<br/>
        Harvesting Knowledge 
      </StyledHeading>
      <StyledParagraph> Imagine a future where every student, armed with the knowledge of Arduino, becomes an agent of positive change in the agricultural landscape. This is the vision that propels Slash Tech. </StyledParagraph>
      <br/>
      <StyledButton to="/services">Explor all services</StyledButton>
    </StyledContentContainer>
    
    <StyledWaveBackground width="1440" height="797" viewBox="0 0 1440 797" fill="none">
      <path d="M1440 575C1025.48 572.005 817.486 0 0 0V796.5H1440V575Z" fill="#141823"/>
    </StyledWaveBackground>
  </StyledSection>
);

export default HeroSection;
import {
  StyledInformationContainer,
  StyledHeading
} from "./StyledCelestialObjectInformation";

import React from "react";

const CelestialObjectInformation = ({ className, data }) => (
  <StyledInformationContainer className={className}>
    <StyledHeading>Information</StyledHeading>
    <span>{data.info || 'Unknown'}</span>
    <br/>
    <b>Options:</b>
    <br/>
    <spanop>
      <React.Fragment>
        {data.options.map((option) => (
          <li>{option || 'Unknown'}</li>
        ))}
      </React.Fragment>
    </spanop>
    <br/>
  </StyledInformationContainer>
);

export default CelestialObjectInformation;
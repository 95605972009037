import wshop from "./imges/Workshop-modified.png"
import tech from "./imges/Technology-modified.png"
import grant from "./imges/Grants-modified.png"
import comm from "./imges/Comm-modified.png"
import dev from "./imges/Dev-modified.png"
import curr from "./imges/Curri-modified.png"
import ases from "./imges/Assesment-modified.png"
import con from "./imges/Consultations-modified.png"

export const endpoints = {
  bodies: 'https://api.le-systeme-solaire.net/rest/bodies'
}

export const colors = {
  mercure: '#FFBFAB',
  venus:   '#E7F6A7',
  terre:   '#1E84FD',
  mars:    '#FD531E',
  jupiter: '#FF9E44',
  saturne: '#F5D86D',
  uranus:  '#CBFFF3',
  neptune: '#2CD8FE',
  moon:    '#A7A7A7'
}

export const planets = [
  {
    id: 'SLT-001', 
    name: 'Workshops', 
    description: 'Engaging hands-on workshops covering various technology topics.',
    info: 'Our learning workshops are designed to inspire and educate students in the fields of programming, robotics, electronics, and more. Led by experienced instructors, these workshops provide participants with practical skills and knowledge through interactive activities and projects. From beginner to advanced levels, our workshops cater to diverse interests and skill levels, fostering a love for technology and innovation.', 
    color: wshop,
    options: ['Introduction to Coding', 'Environmental Monitoring', 'Advanced Arduino Projects', 'Web Development Course',  'Artificial Intelligence Basics', 'Game Dev Workshop', 'Smart Agriculture', 'IoT with Arduino', 'Robotics 101', 'More ...']
  },

  {
    id: 'SLT-002',   
    name: 'Technology', 
    description: 'Seamless integration of technology into school curricula and environments.',
    info: "SlashTech offers comprehensive support for integrating technology into educational settings. Whether it's setting up maker spaces, coding clubs, or implementing STEM programs, we work closely with schools to develop customized solutions that align with their goals and objectives. Our expertise in technology integration ensures a smooth transition and maximizes the benefits of technology for both educators and students.", 
    color: tech,
    options: ['Maker Space Setup', 'Technology Integration', 'Augmented Reality (AR)', 'Online Learning Platforms', 'Digital Collaboration Tools', 'Virtual Reality (VR)', 'STEM Programs', 'Robotics Kits', 'Data Analytics', 'Assessment Tools', 'Coding Clubs', '3D Printing', 'More ...']
  },

  {
    id: 'SLT-003',   
    name: 'Grants', 
    description: 'Guidance and support in securing grants for technology and agriculture initiatives.', 
    info: 'We understand the importance of funding in implementing technology projects in schools. SlashTech provides expert assistance in researching available grants, writing compelling proposals, and navigating the grant application process. Our goal is to help schools access the resources they need to enhance their technology infrastructure and educational offerings, ultimately enriching the learning experience for students.', 
    color: grant,
    options: ['Grant Research', 'Proposal Writing', 'Application Assistance']
  },

  {
    id: 'SLT-004',    
    name: 'Commercial', 
    description: 'Customizable technology solutions for commercial and domestic applications.', 
    info: "In addition to educational projects, SlashTech offers commercial and domestic variations of our technology solutions. Whether it's for commercial applications, home automation, or personal projects, we provide customizable solutions to meet diverse needs. Our team works closely with clients to understand their requirements and develop tailored solutions that deliver results.",  
    color: comm,
    options: ['Commercial Tech solutions', 'Home Automation Systems', 'Digital Marketing Solutions', 'Custom Projects' ]
  },

  {
    id: 'SLT-005', 
    name: 'Development', 
    description: 'Training programs for educators to enhance technology integration skills.',
    info: 'SlashTech offers professional development programs designed to equip educators with the skills and knowledge needed to effectively integrate technology into their teaching practices. Our training sessions cover a wide range of topics, including instructional technology tools, digital literacy, and technology-enhanced learning strategies. By investing in professional development, schools can empower their teachers to leverage technology to enhance student learning outcomes.',
    color: dev,
    options: ['Professional Development', 'Curriculum Integration', 'Educational Tech Coaching', 'Support and Resources', 'More ...']
  },

  {
    id: 'SLT-006', 
    name: 'Curriculum ', 
    description: 'Customized curriculum development services tailored to technology education.', 
    info: 'SlashTech collaborates with schools to develop customized technology curriculum that aligns with educational standards and learning objectives. Our curriculum development services cover a range of technology topics, from computer science and coding to robotics and engineering. We work closely with educators to ensure that the curriculum meets the needs of their students and integrates seamlessly into existing educational frameworks.',
    color: curr,
    options: ['Curriculum Development', 'Build On Frameworks', 'Assessment and Evaluation', 'Customization and Flexibility', 'Ongoing Support', 'More ...']
  },

  {
    id: 'SLT-007',  
    name: 'Assessment', 
    description: 'Comprehensive assessments to evaluate technology infrastructure and needs.', 
    info: ' SlashTech conducts thorough assessments of technology infrastructure and needs to help schools identify strengths, weaknesses, and opportunities for improvement. Our assessments cover areas such as hardware and software capabilities, network infrastructure, digital resources, and professional development needs. By gaining insights from these assessments, schools can make informed decisions and prioritize investments in technology.',
    color: ases,
    options: ['Technology Assessment', 'Network Evaluation', 'Digital Resources Evaluation', 'Data Privacy Assessment', 'Data Security Assessment', 'Monitoring and Evaluation', 'More ...']
  },

  {
    id: 'SLT-008', 
    name: 'Consultation', 
    description: 'Expert consultation services to provide guidance on technology-related initiatives.',
    info: "SlashTech offers expert consultation services to schools seeking guidance on technology-related initiatives. Whether it's developing a technology roadmap, evaluating technology solutions, or addressing specific challenges, our team of technology experts provides valuable insights and recommendations. We work collaboratively with schools to develop strategic plans and implement effective technology solutions that support their educational goals.",
    color: con,
    options: ['Roadmap Development', 'Tech Solution Evaluation', 'Implementation Support', 'Development Planning', 'Policy Reviews', 'More ...']
  },
]
import styled from "styled-components";

export const StyledCelestialObjectCardsContainer = styled.div`
  display: flex;
  max-width: 100%;
  padding: 5%;
  flex-direction: column;
  gap: 1em;

  @media (min-width: 50em) {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }

  @media (min-width: 65em) {
    grid-template-columns: repeat(3, 1fr);
  }

  @media (min-width: 80em) {
    grid-template-columns: repeat(4, 1fr);
  }
`;
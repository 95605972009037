import styled from "styled-components";

import CelestialObject from "../CelestialObject/CelestialObject";
import Button from "../Button/Button";

export const StyledCard = styled.div`
  background-color: #141823;
  padding: 2em;
  display: flex;
  flex-direction: column;
  border-radius: .5em;
  align-items: center;
`;

export const StyledCelestialObject = styled(CelestialObject)`
  width: 8em;
  height: 8em;
  aspect-ratio: 1 / 1;
`;

export const StyledDescription = styled.p`
  text-align: center;
  color: #FFFFFF;
  padding-block: 1em 2em;
  font-weight: 300;
  width: 80%;
  height: 40%;
`;

export const StyledButton = styled(Button)`
  width: 80%;
`;
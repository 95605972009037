import styled from "styled-components";

const HeaderStyle = styled.div`
  display: flex;
  justify-content: space-between;
  //   align-items: flex-end;
  padding-block: 1em;
  background-color: #0f131fc4;
  height: 17vh;

  @media (max-width: 1000px) {
    height: 20vh;
  }

  > a {
    margin-top: 2vh;
    font-weight: bold;
    font-size: 1.9rem;
    text-decoration: none;
  }

  @media (max-width: 1000px) {
    .navbar-wrapper {
      background-color: none;
      height: 10vh;
      width: 40vw;
      display: flex;
      justify-content: flex-end;
    }

    .navbar-wrapper > div {
      flex-direction: column;
      justify-content: space-between;
    }
  }
`;

export { HeaderStyle };
